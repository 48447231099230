import React, { useRef, useCallback, ReactElement, useEffect } from 'react'
import { useEventListener, useForceUpdate } from 'hooks'
import equal from 'fast-deep-equal'
import { resolveRef } from 'helpers/getters'


type StickyContainerProps = {
  children: ReactElement
  className?: string
  offsetTop?: number
}

const StickyContainer: React.FC<StickyContainerProps> = (props) => {
  const { className, offsetTop = 0, children } = props

  const containerRef = useRef<HTMLDivElement>(null)
  const nodeRef = useRef<HTMLDivElement>(null)

  const styleRef = useRef<React.CSSProperties>({})
  const forceUpdate = useForceUpdate()

  const handler = useCallback(() => {
    if (!containerRef.current || !nodeRef.current) {
      return
    }

    const containerRect = containerRef.current.getBoundingClientRect()

    const style = containerRect.top < offsetTop ? {
      width: `${containerRect.width}px`,
      height: `${containerRect.height}px`,
      position: 'fixed',
      top: `${offsetTop}px`,
      zIndex: 1001,
    } : {}

    if (!equal(styleRef.current, style)) {
      styleRef.current = style
      forceUpdate()
    }
  }, [ forceUpdate, offsetTop ])

  useEventListener('scroll', handler)
  useEventListener('resize', handler)

  useEffect(() => {
    resolveRef(nodeRef)
      .then(() => handler())
  }, [ handler ])

  return (
    <div
      ref={containerRef}
      className={className}
      style={{
        height: styleRef.current.height,
      }}
    >
      <div
        ref={nodeRef}
        style={styleRef.current}
      >
        {children}
      </div>
    </div>
  )
}


export default React.memo(StickyContainer)
