import React, { forwardRef, ReactNode } from 'react'
import cx from 'classnames'

import s from './WidthContainerCompensator.scss'


type WidthContainerCompensatorProps = {
  children?: ReactNode
  className?: string
}

const WidthContainerCompensator = forwardRef<HTMLDivElement, WidthContainerCompensatorProps>((props, ref) => {
  const { children, className } = props

  return (
    <div ref={ref} className={cx(className, s.widthContainerCompensator)}>
      {children}
    </div>
  )
})

WidthContainerCompensator.displayName = 'WidthContainerCompensator'


export default WidthContainerCompensator
