import React, { useRef, useCallback, ReactElement, useEffect, useState } from 'react'
import { useEventListener, useForceUpdate } from 'hooks'
import equal from 'fast-deep-equal'
import { resolveRef } from 'helpers/getters'


type StickyContainerProps = {
  children: ({ isDrop: boolean, rect: DomRect }) => ReactElement
  className?: string
  offsetTop?: number
  dropOffset?: number
  placement?: 'right' | 'left'
}

const StickyDropContainer: React.FC<StickyContainerProps> = (props) => {
  const { className, offsetTop = 0, dropOffset = 0, placement = 'left', children } = props

  const [ isDrop, setDrop ] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)
  const nodeRef = useRef<HTMLDivElement>(null)
  const rectRef = useRef<DOMRect>(null)

  const isDropRef = useRef<boolean>(isDrop)
  const styleRef = useRef<React.CSSProperties>({})
  const forceUpdate = useForceUpdate()

  const handler = useCallback(() => {
    if (!containerRef.current || !nodeRef.current) {
      return
    }

    const containerRect = containerRef.current.getBoundingClientRect()
    const isHidden = containerRect.top + containerRect.height < 0
    const isDrop = containerRect.top + containerRect.height + dropOffset < 0

    const placementValue = placement === 'right'
      ? window.innerWidth - containerRect.left - containerRect.width
      : containerRect.left

    let style = {}

    if (isDrop) {
      style = {
        position: 'fixed',
        top: `${offsetTop}px`,
        [placement]: `${placementValue}px`,
        zIndex: 100,
        height: `${containerRect.height}px`,
      }
    }
    else if (isHidden) {
      style = {
        position: 'fixed',
        top: `-${containerRect.height + offsetTop}px`,
        [placement]: `${placementValue}px`,
        zIndex: 100,
        height: `${containerRect.height}px`,
      }
    }

    if (isDropRef.current !== isDrop) {
      styleRef.current = style
      isDropRef.current = isDrop
      setDrop(isDrop)
    }
    else if (!equal(styleRef.current, style)) {
      styleRef.current = style
      forceUpdate()
    }
    else if (rectRef.current?.width !== containerRect.width) {
      rectRef.current = containerRect
      forceUpdate()
    }
  }, [ forceUpdate, offsetTop, dropOffset, placement ])

  useEventListener('scroll', handler)
  useEventListener('resize', handler)

  useEffect(() => {
    resolveRef(containerRef)
      .then(() => handler())
  }, [ handler ])

  return (
    <div
      ref={containerRef}
      className={className}
      style={{
        height: styleRef.current.height,
      }}
    >
      <div
        ref={nodeRef}
        className="ease-transition"
        style={styleRef.current}
      >
        {children({ isDrop, rect: rectRef.current })}
      </div>
    </div>
  )
}


export default React.memo(StickyDropContainer)
