import React from 'react'
import cx from 'classnames'

import type { ColumnsSize, GutterSize } from '../../Grid/Grid'

import s from './Cell.scss'


export type GridCellProps = {
  className?: string
  tag?: string
  columns?: ColumnsSize
  size?: ColumnsSize
  gutter?: GutterSize
  tabIndex?: number
  ariaLabelledby?: string
}

const Cell: React.FC<GridCellProps> = (props) => {
  let {
    children, className, tag = 'div',
    columns = 1, size = 1, gutter,
    tabIndex, ariaLabelledby,
  } = props

  const rootClassName = cx(className, s.cell, s[`gutter-${gutter}`])
  const width = `${(size >= columns ? 1 : size / columns) * 100}%`

  const nodeProps = {
    className: rootClassName,
    style: {
      flexBasis: width,
      width,
    },
    tabIndex,
    role: 'listitem',
    'aria-labelledby': ariaLabelledby,
  }

  return React.createElement(tag, nodeProps, children)
}


export default Cell
