import React, { CSSProperties, forwardRef, ReactNode } from 'react'
import cx from 'classnames'

import s from './ScrollableContainer.scss'


type ScrollableContainerProps = {
  children?: ReactNode | ReactNode[]
  className?: string
  withScrollbar?: boolean
  style?: CSSProperties
  overflow?: 'horizontal' | 'vertical'
}

const ScrollableContainer = forwardRef<HTMLDivElement, ScrollableContainerProps>((props, ref) => {
  const { children, className, withScrollbar, style, overflow = 'vertical' } = props

  return (
    <div
      ref={ref}
      className={cx(className, 'w-full', {
        [s.containerX]: overflow === 'horizontal',
        [s.containerY]: overflow === 'vertical',
        [s.withScrollbar]: withScrollbar,
      })}
      style={style}
    >
      {children}
    </div>
  )
})


export default ScrollableContainer
