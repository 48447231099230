import React, { useRef, useCallback, ReactElement } from 'react'
import { useEventListener, useForceUpdate } from 'hooks'
import equal from 'fast-deep-equal'


const FloatingContainer: React.FC<{ children: ReactElement, offsetTop?: number }> = ({ children, offsetTop = 0 }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const nodeRef = useRef<HTMLDivElement>(null)

  const styleRef = useRef<React.CSSProperties>(null)
  const forceUpdate = useForceUpdate()

  const handler = useCallback(() => {
    if (!containerRef.current || !nodeRef.current) {
      return
    }

    const containerRect = containerRef.current.getBoundingClientRect()

    // top is default position
    let style = {}

    if (containerRect.height - nodeRef.current.offsetHeight > 1) {
      if (containerRect.top < 0 && (containerRect.top + containerRect.height) <= nodeRef.current.offsetHeight) {
        // bottom
        style = {
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
        }
      }
      else if (containerRect.top < 0) {
        // fixed
        style = {
          position: 'fixed',
          left: containerRect.left,
          width: containerRect.width,
          top: `${offsetTop}px`,
        }
      }
    }

    if (!equal(styleRef.current, style)) {
      styleRef.current = style
      forceUpdate()
    }
  }, [ forceUpdate, offsetTop ])

  useEventListener('scroll', handler)
  useEventListener('resize', handler)

  return (
    <div ref={containerRef} className="relative h-full">
      <div ref={nodeRef} style={styleRef.current}>
        {children}
      </div>
    </div>
  )
}


export default React.memo(FloatingContainer)
