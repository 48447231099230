import React, { forwardRef, ReactNode } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import s from './WidthContainer.scss'


type WidthContainerProps = {
  children?: ReactNode
  className?: string
  id?: string
  ignore?: boolean
  narrow?: boolean
  dataTestId?: string
}

const WidthContainer = forwardRef<HTMLDivElement, WidthContainerProps>((props, ref) => {
  const { children, className, id, ignore, narrow, dataTestId } = props

  const { isMobile, isTablet } = useDevice()

  const paddingClassName = isMobile ? 'px-24px' : isTablet ? 'px-32px' : 'px-40px'

  const rootClassName = cx(className, {
    [paddingClassName]: !ignore,
    [s.widthContainer]: !ignore,
    'mw-618': !ignore && narrow,
  })

  return (
    <div ref={ref} id={id} className={rootClassName} data-testid={dataTestId}>
      {children}
    </div>
  )
})

WidthContainer.displayName = 'WidthContainer'


export default WidthContainer
