import React from 'react'
import cx from 'classnames'

import s from './Cols.scss'


type ColsProps = {
  className?: string
  layout?: 'admin' | 'modal' | 'user' | 'ignore'
}

const Cols: React.FC<ColsProps> = (props) => {
  const { children, className, layout = 'admin' } = props

  return (
    <div className={cx(className, s[layout])}>
      {children}
    </div>
  )
}


export default Cols
