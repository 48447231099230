import React, { CSSProperties, useMemo } from 'react'
import cx from 'classnames'

import type { TextSize } from 'components/dataDisplay'

import { getTextSize } from './util'

import s from './Bone.scss'


type BoneProps = Pick<IndentMap, 'mt' | 'mr' | 'mb' | 'ml'> & {
  className?: string // used only for <Box noWrapper><Bone /></Box>
  w?: number
  h?: number
  pw?: number // percent width
  ph?: number // percent height
  textSize?: TextSize
  aspect?: number // w / h, e.g. 1.33
  circle?: boolean
  inline?: boolean
  style?: CSSProperties
}

const Bone: React.FC<BoneProps> = (props) => {
  const {
    className,
    w: width, h: height,
    pw: percentWidth, ph: percentHeight,
    mt, mr, mb, ml,
    textSize,
    aspect, circle, inline = false,
    style: nodeStyle = {},
  } = props

  const style = useMemo(() => {
    const style: any = {}

    if (width) {
      style.width = style.minWidth = `${width}px`
    }

    if (percentWidth) {
      style.width = style.minWidth = `${percentWidth}%`
    }

    if (textSize) {
      const size = getTextSize(textSize) || {}

      style.height = style.minHeight = `${size}px`
    }
    else {
      if (height) {
        style.height = style.minHeight = `${height}px`
      }

      if (percentHeight) {
        style.height = style.minHeight = `${percentHeight}%`
      }
    }

    return {
      ...style,
      ...nodeStyle,
    }
  }, [ width, height, percentWidth, percentHeight, nodeStyle, textSize ])

  const skeletonClassName = cx('bone', className, {
    [`mt-${mt}px`]: mt,
    [`mr-${mr}px`]: mr,
    [`mb-${mb}px`]: mb,
    [`ml-${ml}px`]: ml,
    'inline': inline,
    [s.circle]: circle,
  })

  return (
    <div
      className={skeletonClassName}
      style={style}
    >
      {
        aspect && (
          <div style={{ paddingTop: `${Math.round(100 / aspect)}%` }} />
        )
      }
    </div>
  )
}


export default React.memo(Bone)
