import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'


type PortalProps = {
  id: string
}

const Portal: React.FC<PortalProps> = (props) => {
  const { children, id } = props
  const [ elementId, setElementId ] = useState(null)

  useEffect(() => {
    const checkElement = () => {
      if (document.getElementById(id)) {
        setElementId(id)
        return true
      }
    }

    if (!checkElement()) {
      let interval
      interval = setInterval(() => {
        if (checkElement()) {
          clearInterval(interval)
        }
      }, 100)

      return () => {
        clearInterval(interval)
      }
    }
  }, [ id ])

  if (__SERVER__ || !elementId) {
    return null
  }

  return createPortal(children, document.getElementById(elementId))
}


export default Portal
